/* eslint-disable max-lines */
import { CheckHandwrite } from '@pretto/picto'
import Layout from '@pretto/website/src/components/Layout'
import SEO from '@pretto/website/src/components/SEO'
import Schema from '@pretto/website/src/components/Schema'
import { HERO_MEDIA, ROW_MEDIA } from '@pretto/website/src/config/rowsNames'
import { chooseRelevantDate } from '@pretto/website/src/lib/chooseRelevantDate'
import { Footer } from '@pretto/website/src/postTypes/post/components/Footer/Footer'
import { Dispatcher } from '@pretto/website/src/postTypes/post/controllers/dispatcher/dispatcher'

import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import { useMemo } from 'react'

const __components__ = { CheckHandwrite }

const Post = props => {
  const doesTemplateContainMedia =
    props.data.wpPost.template?.about?.hero?.[0]?.__typename === HERO_MEDIA ||
    props.data.wpPost.template?.about?.rows?.some(row => row.__typename === ROW_MEDIA)

  const breadcrumbItems = useMemo(
    () => [
      ...[...(props.data.wpPost.primaryCategory.node.ancestors?.nodes ?? [])].reverse(),
      ...(props.data.wpPost.primaryCategory.node.databaseId !== props.data.wp.writingSettings.defaultCategory
        ? [{ title: props.data.wpPost.primaryCategory.node.name, url: props.data.wpPost.primaryCategory.node.uri }]
        : []),
      {
        title: props.data.currentCategory?.name ?? props.data.wpPost.title,
        url: props.data.currentCategory?.uri ?? props.data.wpPost.uri,
      },
    ],
    []
  )

  const Post = (
    <Layout
      breadcrumbItems={breadcrumbItems}
      isSecondaryMenuActive={props.pageContext.shouldIncludeSecondaryMenu}
      template={props.data.wpPost.template}
      topBannerSettings={props.data.wp.acfOptionsHomeSettings.topBanner}
    >
      <Dispatcher {...chooseRelevantDate(props)} pictoComponents={__components__} />

      {!doesTemplateContainMedia && <Footer {...props} />}

      <Schema
        breadcrumbItems={breadcrumbItems}
        data={{
          author: props.data.wpPost.author.node,
          date: props.data.wpPost.date,
          description: props.data.wpPost.seo.description,
          image: props.data.wpPost.postTypeMeta.highlightedImage,
          modified: props.data.wpPost.modified,
          title: props.data.wpPost.title,
        }}
      />

      <SEO
        {...props.data.wpPost.seo}
        category={props.data.highestCategory.name}
        date={props.data.wpPost.date}
        image={props.data.wpPost.postTypeMeta.highlightedImage}
        modified={props.data.wpPost.modified}
        template={props.data.wpPost.template}
      />
    </Layout>
  )

  const doesPostIncludeQuestion = props.data.wpPost.content?.includes('<Question title="')

  if (doesPostIncludeQuestion) {
    return (
      <div itemScope itemType="https://schema.org/FAQPage">
        {Post}
      </div>
    )
  }
  return Post
}

Post.propTypes = {
  data: PropTypes.shape({
    currentCategory: PropTypes.shape({
      name: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }),
    highestCategory: PropTypes.shape({
      name: PropTypes.string.isRequired,
    }).isRequired,
    wpPost: PropTypes.shape({
      author: PropTypes.shape({
        node: PropTypes.object.isRequired,
      }).isRequired,
      content: PropTypes.string.isRequired,
      categories: PropTypes.object,
      date: PropTypes.string.isRequired,
      modified: PropTypes.string,
      primaryCategory: PropTypes.shape({
        node: PropTypes.shape({
          ancestors: PropTypes.shape({
            nodes: PropTypes.array.isRequired,
          }),
          databaseId: PropTypes.number.isRequired,
          name: PropTypes.string.isRequired,
          uri: PropTypes.string.isRequired,
        }).isRequired,
      }).isRequired,
      postTypeMeta: PropTypes.shape({ highlightedImage: PropTypes.string.isRequired }).isRequired,
      seo: PropTypes.object.isRequired,
      template: PropTypes.object,
      title: PropTypes.string.isRequired,
      uri: PropTypes.string.isRequired,
    }).isRequired,
    wp: PropTypes.shape({
      writingSettings: PropTypes.shape({
        defaultCategory: PropTypes.number.isRequired,
      }).isRequired,
      acfOptionsHomeSettings: PropTypes.object,
    }).isRequired,
  }).isRequired,
  pageContext: PropTypes.object.isRequired,
}

export const query = graphql`
  query Post__CheckHandwrite(
    $bankSlug: String
    $highestAncestorCategoryId: String!
    $id: String!
    $secondaryMenuId: Int
    $shouldIncludeArticlesCategories: Boolean!
    $shouldIncludeBankData: Boolean!
    $shouldIncludeRateTemplateData: Boolean!
    $shouldIncludeSecondaryMenu: Boolean!
    $slug: String!
    $tags: [String!]!
    $templateBlocsArticlesCategories: [String]
    $templateBlocsTags: [String!]!
  ) {
    articles: allWpPost(
      filter: { categories: { nodes: { elemMatch: { slug: { in: $templateBlocsArticlesCategories } } } } }
    ) @include(if: $shouldIncludeArticlesCategories) {
      nodes {
        uri
        title
        modified
        date
        postTypeMeta {
          highlightedimage
        }
        categories {
          nodes {
            slug
          }
        }
      }
    }
    articlesCategory: allWpCategory(filter: { slug: { in: $templateBlocsArticlesCategories } })
      @include(if: $shouldIncludeArticlesCategories) {
      nodes {
        name
        slug
      }
    }
    allWpTag(filter: { name: { in: $tags } }) {
      nodes {
        name
        posts {
          nodes {
            categories {
              nodes {
                databaseId
              }
            }
            databaseId
            date
            modified
            title
            uri
          }
        }
      }
    }
    allTemplateBlocsTag: allWpTag(filter: { name: { in: $templateBlocsTags } }) {
      nodes {
        name
        posts {
          nodes {
            categories {
              nodes {
                databaseId
              }
            }
            databaseId
            date
            modified
            title
            uri
          }
        }
      }
    }
    bankGateway: prettoGateway @include(if: $shouldIncludeBankData) {
      airtable {
        banks(filter: { slug: $bankSlug }) {
          bankin_grade
          number_branches
          score
          type
          group
          application_fees
          account_fees
          fixed_rate
          primary_residence
          secondary_residence
          rental_investment
          repurchase
          financing_works
          guarantee
          ippd
          hypotheque
          scoring_ira
          ira_description
          scoring_modularity
          modularity_description
          scoring_report_deadline
          report_deadline_description
        }
      }
    }
    currentCategory: wpCategory(slug: { eq: $slug }) {
      id
      name
      posts {
        nodes {
          date
          excerpt
          modified
          postTypeMeta {
            highlightedImage: highlightedimage
          }
          title
          titleVariations {
            variations {
              content
            }
          }
          uri
        }
      }
      uri
      wpChildren {
        nodes {
          path: uri
          title: name
        }
      }
      wpParent {
        node {
          posts {
            nodes {
              id
              path: uri
              title
              titleVariations {
                variations {
                  content
                }
              }
            }
          }
        }
      }
    }
    highestCategory: wpCategory(id: { eq: $highestAncestorCategoryId }) {
      databaseId
      name
    }
    rates(id: { ne: "dummy" }) {
      data {
        lastExecutionDate: last_execution_date @include(if: $shouldIncludeRateTemplateData)
        marketRatesHistory: market_rates_history @include(if: $shouldIncludeRateTemplateData) {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesRegionHistory: market_rates_region_history @include(if: $shouldIncludeRateTemplateData) {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          region
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesLatest: market_rates_latest {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          sigma
          veryGoodRate: very_good_rate
        }
        marketRatesProfileLatest: market_rates_profile_latest @include(if: $shouldIncludeRateTemplateData) {
          averageRate: average_rate
          date
          duration
          mortgagors: nb_mortgagors
          ordinaryRate: ordinary_rate
          salaryMax: salary_max
          salaryMin: salary_min
          sigma
          trend
          veryGoodRate: very_good_rate
        }
        marketRatesProfileRegionLatest: market_rates_profile_region_latest
          @include(if: $shouldIncludeRateTemplateData) {
          averageRate: average_rate
          date
          duration
          maxOrdinaryRate: max_ordinary_rate_display
          maxVeryGoodRate: max_very_good_rate_display
          minOrdinaryRate: min_ordinary_rate_display
          minVeryGoodRate: min_very_good_rate_display
          mortgagors: nb_mortgagors
          ordinaryRate: ordinary_rate
          region
          salaryMax: salary_max
          salaryMin: salary_min
          sigma
          trend
          veryGoodRate: very_good_rate
        }
        marketRatesRegionLatest: market_rates_region_latest {
          averageRate: average_rate
          date
          duration
          ordinaryRate: ordinary_rate
          region
          sigma
          veryGoodRate: very_good_rate
        }
      }
    }
    secondaryMenu: allWpMenu(filter: { databaseId: { eq: $secondaryMenuId } })
      @include(if: $shouldIncludeSecondaryMenu) {
      nodes {
        menuItems {
          nodes {
            uri
            label
          }
        }
      }
    }
    wp {
      acfOptionsRatesSettings @include(if: $shouldIncludeRateTemplateData) {
        ratesSettings {
          anchor: monthlyUpdateAnchor
          news: monthlyUpdateNews {
            ... on WpPost {
              date
              excerpt
              uri
            }
          }
          title: monthlyUpdateTitle
        }
      }
      acfOptionsHomeSettings {
        topBanner: homeSettings {
          content: homeSettingsBannerContent
          isDisplayed: homeSettingsBannerIsDisplayed
          location: homeSettingsBannerLocation
        }
      }
      writingSettings {
        defaultCategory
      }
    }
    wpPost(id: { eq: $id }) {
      author {
        node {
          ...authorSignature
        }
      }
      categories {
        nodes {
          ancestors {
            nodes {
              databaseId
            }
          }
          databaseId
        }
      }
      comments {
        nodes {
          ...comment
        }
      }
      content
      date
      databaseId
      id
      modified
      post {
        options
      }
      postTypeMeta {
        highlightedImage: highlightedimage
        isSidebarMenuActive: issidebarmenuactive
        isSummaryDisplayed: issummarydisplayed
        summaryColor: summarycolor
      }
      primaryCategory {
        node {
          ancestors {
            nodes {
              title: name
              url: uri
            }
          }
          databaseId
          id
          name
          posts {
            nodes {
              date
              excerpt
              id
              modified
              postTypeMeta {
                highlightedImage: highlightedimage
              }
              title
              titleVariations {
                variations {
                  content
                }
              }
              uri
            }
          }
          uri
        }
      }
      seo {
        canonical
        description: metaDesc
        noindex: metaRobotsNoindex
        title
      }
      template {
        __typename
        ... on WpTemplate_Blocs {
          ...BlocsTemplate
        }
        ... on WpTemplate_SimulateurAvanc {
          ...SimulateurAvanceTemplate
        }
        ... on WpTemplate_SimulateurPrtImmobilier {
          ...SimulateurPretImmobilierTemplate
        }
        ... on WpTemplate_SimulateurAvancancienneVersion {
          ...SimulateurAvanceAncienneVersionTemplate
        }
        ... on WpTemplate_SimulateurCondens {
          ...SimulateurCondenseTemplate
        }
        ... on WpTemplate_SimulateurCondensversionCalculette {
          ...SimulateurConsenseVersionCalculetteTemplate
        }
        ... on WpTemplate_SimulateurParBanque {
          ...SimulateurBanqueTemplate
        }
        ... on WpTemplate_TauxImmobilier {
          ...TauxImmobilierTemplate
        }
        ... on WpTemplate_TauxImmobilierBaromtre {
          ...TauxImmobilierBaromtreTemplate
        }
        ... on WpTemplate_TauxImmobilierDure {
          ...TauxImmobilierDureTemplate
        }
        ... on WpTemplate_TauxImmobilierDureRgion {
          ...TauxImmobilierDureRgionTemplate
        }
        ... on WpTemplate_TauxImmobilierRgion {
          ...TauxImmobilierRgionTemplate
        }
        ... on WpTemplate_TauxImmobilierParBanque {
          ...TauxImmobilierBanqueTemplate
        }
      }
      slug
      title
      uri
    }
  }
`

export default Post
